import React from "react";
import { Box, Container, Typography, Link } from "@mui/material";

import "../styles/App.css";

const Copyright = () => {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {"Copyright © "}
            {new Date().getFullYear()}
            {" - "}
            <Link className="App-link" href="#">
                StartupManch Pvt. Ltd.
            </Link>
            {" All Rights reserved."}
        </Typography>
    );
};

const StickyFooter = () => {
    return (
        <Box
            component="footer"
            sx={{
                py: 3,
                px: 2,
                mt: "auto",
                backgroundColor: (theme) => (theme.palette.mode === "light" ? theme.palette.grey[200] : theme.palette.grey[800]),
            }}
        >
            <Container>
                {/* <Typography variant="body1">MySticky Header!!!</Typography> */}
                <Copyright />
            </Container>
        </Box>
    );
};

export default StickyFooter;
