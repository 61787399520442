import "./styles/App.css";

import SFooter from "./Components/StickyFooter";
import ComingSoon from "./Pages/ComingSoon";

function App() {
    return (
        <div className="App">
            <ComingSoon title="<StartupManch />" description="Website Coming Soon!!" />
            <SFooter />
        </div>
    );
}

export default App;
