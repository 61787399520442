import React from "react";
import { Box, Container, Typography, CssBaseline } from "@mui/material";

import Logo from "../assets/startupmanch-blue.svg";

const ComingSoon = (props) => {
    const { description, title } = props;

    return (
        <Box component="main" sx={{ display: "flex", flexDirection: "column", minHeight: "94vh" }}>
            <CssBaseline />

            <Container sx={{ mt: 2, mb: 2 }}>
                {/* <Box>
                    {/* <Logo /> */}
                {/* <img src={Logo} alt="Startupmanch" height={120} sx={{ mt: 20 }} /> */}
                {/* </Box> */}
                <Typography variant="h2" component="h1" align="center" gutterBottom sx={{ mt: 22, color: "#325ba9" }}>
                    {title}
                </Typography>
                <Typography align="center" color="text.Secondary" component="p" sx={{ mt: 10, fontSize: "22px" }}>
                    {description}
                </Typography>
                <Box sx={{ mt: 10 }}>
                    <img
                        src="https://camo.githubusercontent.com/992babdffd8c74a1502de375fbdf7e4d54773242/68747470733a2f2f6d656469612e67697068792e636f6d2f6d656469612f53576f536b4e36447854737a71494b4571762f67697068792e676966"
                        alt="Under Development!!"
                    />
                </Box>
            </Container>
        </Box>
    );
};

export default ComingSoon;
